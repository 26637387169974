  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Inter', sans-serif;
      /* font-family: 'Poppins', sans-serif; */
  }

  .ProjectD {
      width: 100%;
      height: 100vh;
      background-color: #fff;
  }

  .ProjectD .navbar .nav-item {
      margin: 0px 24px;
      font-weight: 500;
  }
  .ProjectD .navbar .nav-item:hover {
      border-bottom: 1px solid #3661D4;
  }

  .ProjectD .button .btn-login {
      background: #3661D4;
      color: #fff;
      padding: 6px 32px;
      font-size: 20px;
      border-radius: 5px;
  }

  .ProjectD .button .btn-login:hover {
      background: #2e54bf;
      color: #fff;
      /* font-weight: 500; */
  }

  .navbar-nav .nav-link:hover {
      /* border-bottom: 3px solid #3661D4; */
      font-weight: 600;
      color: #111111;
  }
  /* content */

  .c-right {
      height: 91vh;
  }

  .c-left {
      background-image: url("./images/Background1.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 91vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      padding: 0px 128px;
  }

  .c-left .text h3 {
      font-size: 40px;
      color: #ffffff;
      font-weight: 700;
      margin-top: 115px;
  }

  .c-left .text p {
      --font-size: 20px;
      --font-weight: 700;
      margin-top: 30px;
      color: #ffffff;
  }

  .c-left .text2 p {
      font-size: 13px;
      --font-weight: 500;
      color: #ffffff;
	  margin-left: -90px;
  }

  .c-right h3 {
      font-size: 30px;
      margin-bottom: 15px;
      font-weight: 700;

  }

  .c-right .box {
      width: 40%;
  }

  /* .c-right .nav-link:hover {} */

  .c-right .nav-link span {
      font-size: 20px;
  }

  .c-right .nav-link {
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #B5B5B5;
      border-radius: 0px !important;
      background-color: none;
      color: #B5B5B5;
      transition: none;

  }

  .c-right .nav-link.active {
      /* font-size: 14px; */
      font-weight: 500;
      border-bottom: 1px solid #3661D4;
      border-radius: 0px !important;
      background-color: white !important;
      color: #000 !important;
      transition: none;
  }

  .tab-content .form-control {
      background: #F7F7F7;
      padding: 10px 10px;
  }
  .tab-content .fade {
        transition: opacity 0s linear;
  }

  .form-control:focus {
      border: 2px solid #3661D4;
      box-shadow: none !important;
  }

  .c-right .tab-content .btn-continue {
      background: #3661D4;
      color: #fff;
      padding: 6px 32px;
      font-size: 20px;
      border-radius: 5px;
  }

  .c-right .tab-content .btn-continue:hover {
      background: #2e54bf;
      color: #fff;
      /* font-weight: 500; */
  }

  @media (min-width: 1024px) and (max-width: 1400px) {
      .c-right .box {
          width: 50%;
      }
  }

  @media (max-width: 1024px) {
      .c-right .box {
          width: 70%;
      }

      .c-left {
          padding: 0px 70px
      }

      .ProjectD .navbar .nav-item {
          margin: 0px 9px;
      }

      .c-left .text h3 {
          margin-top: 100px;
      }

      .c-left .text2 p {
          font-size: 16px;
      }
  }

  @media (max-width: 768px) {
      .c-left {
          padding: 0px 20px;
      }

      .c-right .box {
          width: 70%;
      }

      .c-left .text h3 {
          font-size: 30px;
          margin-top: 43px;
      }

      .c-left .text p {
          font-size: 14px;
      }

      .c-left .text2 p {
          font-size: 13px;
          font-weight: 500;
          color: #FFFFFF;
      }

      .c-right h3 {
          font-size: 30px;
          margin-bottom: 15px;
      }

  }

  /* Media Queries for Mobile */
  @media (max-width: 576px) {
      .ProjectD {
          overflow-y: scroll;

      }

      .c-right .box {
          width: 100%;
      }

      .navbar-brand {
          width: 190px;
      }

      .c-left .text h3 {
          margin-top: 60px;
      }

      .c-left {
          padding: 0px 60px;
          height: 37vh;
      }

      .c-right {
          height: 67vh;
          padding: 0px 18px;
      }
  }
  
  .img-fluid {
    /* max-width: 100%; */
    height: 81px;
    width: 168px;
    margin-top: -19px;
	}